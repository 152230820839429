import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Icon, Text, Box, Flex, Modal } from '@feast-it/pesto';

import Input from '../Input';
import { trackEvent } from '../../modules/analytics';
import { NEWSLETTER_SUBSCRIBED } from '../../constants/analytics';
import useScript from '../../hooks/useScript';
import config from '../../config';

import * as Styled from './styles';

const initialValues = {
  email: '',
};

const createSchema = Yup.object().shape({
  email: Yup.string()
    .email('Incorrect email address')
    .required('Required'),
});

const NewsletterSignup = () => {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);
  const [modalIsOpen, setModalVisibility] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Required setup for Drip script
  useEffect(() => {
    var _dcq = _dcq || [];
    var _dcs = _dcs || {};
    _dcs.account = config.dripAccountId;
    window._dcq = _dcq;
    window._dcs = _dcs;
  }, []);

  // Callback function subscribes email to Drip
  const subscribeUser = () => {
    window._dcq.push([
      'identify',
      {
        email,
        tags: ['customer-newsletter', 'newsletter-signup'],
        success: () => {
          setModalVisibility(true);
        },
        failure: () => {
          setError(true);
          setModalVisibility(true);
        },
      },
    ]);

    trackEvent(NEWSLETTER_SUBSCRIBED, { email });
  };

  // Load script when isSubmitted is set
  useScript(
    `//tag.getdrip.com/${config.dripAccountId}.js`,
    isSubmitted,
    subscribeUser
  );

  const handleSubmit = ({ email }) => {
    setEmail(email);
    setIsSubmitted(true);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={createSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Styled.Newsletter as={Form}>
            <Field
              component={Input}
              type="email"
              name="email"
              placeholder="Enter email to join our newsletter"
              ariaLabel="Enter email to join our newsletter"
              inlineValidation
              required
              mb={{ _: 6, s: 0 }}
              mr={{ s: 6 }}
            />

            <Button variant="darkOutline" type="submit" disabled={isSubmitted}>
              Submit
            </Button>
          </Styled.Newsletter>
        )}
      </Formik>

      <Modal open={modalIsOpen} handleClose={() => setModalVisibility(false)}>
        <Box p={4}>
          <Flex flexDirection="column">
            <Icon name="HotdogIllustration" color="primary" />

            <Text textAlign="center" color="greyDark" mt={4}>
              {error ? (
                <>Oops, something went wrong. Please try again!</>
              ) : (
                <>
                  Thanks for subscribing to the Feast&nbsp;It&nbsp;newsletter!
                </>
              )}
            </Text>

            <Button onClick={() => setModalVisibility(false)} mt={6}>
              Close
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};

export default NewsletterSignup;
