import styled from '@emotion/styled/macro';
import {
  Image,
  rem,
  minWidth,
  maxWidth,
  Grid,
  Box,
  Heading,
} from '@feast-it/pesto';

const styleArea = (name, theme) => {
  switch (name) {
    case 'newsletter':
      return `
        ${maxWidth(theme.breakpoints.l)} {
          border-bottom: 1px solid ${theme.colors.blueLight};
        }

        ${minWidth(theme.breakpoints.l)} {
          border-top: 1px solid ${theme.colors.blueLight};
        }
      `;
    case 'payments':
      return `
        ${maxWidth(theme.breakpoints.l)} {
          border-top: 1px solid ${theme.colors.blueLight};
        }
      `;
    case 'copyright':
      return `
        border-top: 1px solid ${theme.colors.blueLight};
      `;
    case 'servicesLinks':
      return `
          border-bottom: 1px solid ${theme.colors.blueLight};
        `;
    default:
      return null;
  }
};

export const Footer = styled.footer`
  padding: 0 ${({ theme }) => theme.space.gutter._}
    ${({ theme }) => theme.space[8]};
  border-top: 1px solid ${({ theme }) => theme.colors.blueLight};
  min-height: ${rem(1600)};

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    padding: 0 ${({ theme }) => theme.space.gutter.s}
      ${({ theme }) => theme.space[8]};
    min-height: ${rem(1000)};
  }

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    min-height: ${rem(1210)};
    padding: 0 ${({ theme }) => theme.space.gutter.m}
      ${({ theme }) => theme.space[8]};
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    min-height: ${rem(676)};
    padding-top: ${({ theme }) => theme.space[8]};
  }
`;

export const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'servicesLinks servicesLinks'
    'newsletter newsletter'
    'details details'
    'payments payments'
    'copyright copyright';

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'servicesLinks servicesLinks servicesLinks servicesLinks'
      'newsletter newsletter newsletter newsletter'
      'details details details details'
      'payments payments payments payments'
      'copyright copyright copyright copyright';
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'servicesLinks servicesLinks servicesLinks servicesLinks servicesLinks'
      'details details details _ payments'
      'copyright copyright copyright newsletter newsletter';
  }
`;

export const Area = styled(Box)`
  padding: ${({ theme }) => theme.space[8]} 0;
  grid-area: ${({ name }) => name};
  ${({ name, theme }) => styleArea(name, theme)}
`;

export const Link = styled.a`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${rem(28)};
  color: ${({ theme }) => theme.colors.blueDark};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Header = styled(Heading)`
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const PaymentGrid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.space[4]};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: ${rem(320)};

  ${({ theme }) => maxWidth(theme.breakpoints.l)} {
    margin: 0 auto;
    opacity: 0.7;

    > * {
      margin: 0 auto;
    }
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    grid-template-columns: 1fr 1fr;
    max-width: ${rem(200)};
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    justify-content: flex-start;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    justify-content: flex-start;
  }
`;

export const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.blueDark};

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    color: ${({ theme }) => theme.colors.greyDark};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  & + & {
    margin-left: ${({ theme }) => theme.space[4]};
  }
`;

export const GoogleReviews = styled(Image)`
  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    margin: 0;
  }
`;

export const Reviews = styled.div`
  width: ${rem(140)};
  height: ${rem(125)};
  margin: 0;

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    margin: ${({ theme }) => theme.space[6]} 0 0;
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    margin: ${({ theme }) => theme.space[6]} 0 0;
  }
`;

export const Newsletter = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto ${({ theme }) => theme.space[9]};

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    flex-direction: row;
    max-width: ${rem(400)};
    align-items: flex-start;
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    max-width: none;
    margin: 0 0 ${({ theme }) => theme.space[6]};
  }
`;

export const DetailContainer = styled(Grid)`
  grid-template-areas:
    'info about'
    'reviews reviews';
  column-gap: 10%;

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10%;
    grid-template-areas: 'reviews info about';
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    grid-template-areas: 'reviews info about';
    grid-template-columns: repeat(auto-fit, minmax(200px, 212px));
    column-gap: 0;
  }
`;

export const ServiceContainer = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    margin: 1px;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10%;
  }

  ${({ theme }) => minWidth(theme.breakpoints.l)} {
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    column-gap: 0;
  }
`;

export const ReviewsContainer = styled(Area)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0px;
  column-gap: 12%;

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    grid-template-columns: 1fr;
  }
`;
