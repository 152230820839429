import React from 'react';

import Footer from '../../components/Footer';
import paths from '../../constants/paths';
import { GET_QUOTES_CLICKED } from '../../constants/analytics';
import { trackEvent } from '../../modules/analytics';

const services = {
  wedding: {
    heading: 'Wedding services',
    href: paths.EVENT('wedding-catering'),
    items: [
      {
        text: 'Wedding catering',
        href: paths.EVENT('wedding-catering'),
      },
      {
        text: 'Wedding photography',
        href: paths.EVENT('wedding-photography'),
      },
      {
        text: 'Wedding floristry',
        href: paths.EVENT('wedding-floristry'),
      },
      {
        text: 'London wedding catering',
        href: paths.EVENT('london-wedding-catering'),
      },
      {
        text: 'Food truck catering',
        href: paths.EVENT('food-truck-catering'),
      },
    ],
  },
  corprate: {
    heading: 'Corporate services',
    href: paths.EVENT('corporate-catering'),
    items: [
      {
        text: 'Corporate catering',
        href: paths.EVENT('corporate-catering'),
      },
      {
        text: 'Company parties',
        href: paths.EVENT('company-party'),
      },
      {
        text: 'Christmas parties',
        href: paths.EVENT('christmas-catering'),
      },
      {
        text: 'Corporate photography',
        href: paths.EVENT('corporate-photography'),
      },
      {
        text: 'London dry venue hire',
        href: paths.EVENT('venues-dry-hire-london'),
      },
    ],
  },
  drinks: {
    heading: 'Drink services',
    href: paths.EVENTS('mobile-bars'),
    items: [
      {
        text: 'Mobile bars',
        href: paths.EVENT('mobile-bars'),
      },
      {
        text: 'Cocktail bars',
        href: paths.EVENT('cocktail-bar-hire'),
      },
      {
        text: 'Mobile bars London',
        href: paths.EVENT('mobile-bars-london'),
      },
    ],
  },
  catering: {
    heading: 'Catering services',
    href: paths.EVENTS('catering'),
    items: [
      {
        text: 'Party catering',
        href: paths.EVENT('party-catering'),
      },
      {
        text: 'Street food catering',
        href: paths.EVENT('street-food-catering'),
      },
      {
        text: 'Event catering',
        href: paths.EVENT('event-catering'),
      },
      {
        text: 'Film set catering ',
        href: paths.EVENT('set-catering'),
      },
      {
        text: 'Mobile catering',
        href: paths.EVENT('mobile-catering'),
      },
    ],
  },
  media: {
    heading: 'Photo/Video services',
    href: paths.EVENTS('photographers'),
    items: [
      {
        text: 'Hire a photographer',
        href: paths.EVENT('event-photography'),
      },
      {
        text: 'Event photography',
        href: paths.EVENT('event-photography'),
      },
      {
        text: 'Hire a videographer',
        href: paths.EVENT('videographers'),
      },
    ],
  },
  popular: {
    heading: 'Popular services',
    href: 'https://feast-it.com/sitemap',
    items: [
      {
        text: 'Hog roast catering',
        href: paths.EVENT('hog-roast-catering'),
      },
      {
        text: 'Hire a florist',
        href: paths.EVENT('florists'),
      },
      {
        text: 'Ice cream van hire',
        href: paths.EVENT('ice-cream-van-hire'),
      },
      {
        text: 'Fish and chip van hire',
        href: paths.EVENT('mobile-fish-and-chip'),
      },
      {
        text: 'BBQ catering',
        href: paths.EVENT('bbq-catering'),
      },
    ],
  },
};

const navigation = {
  about: {
    heading: 'About',
    items: [
      {
        text: 'About us',
        href: paths.INFO(),
        as: paths.INFO(paths.INFO_PAGES.ABOUT),
      },
      {
        text: 'How we work',
        href: paths.INFO(),
        as: paths.INFO(paths.INFO_PAGES.HOW_WE_WORK),
      },
      {
        text: 'Our partners',
        href: paths.PARTNERS,
      },
      {
        text: 'Get quotes',
        href: paths.ENQUIRY_FORM,
        onClick: () => trackEvent(GET_QUOTES_CLICKED, { type: 'Footer' }),
      },
      {
        text: 'Magazine',
        href: paths.MAGAZINE,
      },
    ],
  },
  info: {
    heading: 'Info',
    items: [
      {
        text: 'Careers',
        href: paths.CAREERS,
      },
      {
        text: 'Contact us',
        href: paths.CONTACT,
      },
      {
        text: 'T&Cs',
        href: paths.TERMS,
      },
      {
        text: 'Privacy',
        href: paths.PRIVACY,
      },
      {
        text: 'FAQs',
        href: paths.FAQS,
      },
    ],
  },
};

const paymentTypes = ['Visa', 'Mastercard', 'Amex', 'Stripe'];

const socialLinks = [
  {
    type: 'Facebook',
    href: 'https://www.facebook.com/Feastitofficial',
  },
  {
    type: 'Instagram',
    href: 'https://www.instagram.com/feast._.it',
  },
  {
    type: 'Twitter',
    href: 'https://twitter.com/Feast_it',
  },
];

const FooterContainer = () => {
  return (
    <Footer
      navigation={navigation}
      paymentTypes={paymentTypes}
      socialLinks={socialLinks}
      services={services}
    />
  );
};

export default FooterContainer;
