function indexObject(obj, path) {
  const propArray = path.split('.');
  const indexedVal = propArray.reduce((acc, val) => acc[val], obj);
  return indexedVal;
}

export const uniq = array => [...new Set(array)];

export const sortBy = (array, key) =>
  array.sort((a, b) => {
    let rule;
    if (a[key] > b[key]) {
      rule = 1;
    } else if (b[key] > a[key]) {
      rule = -1;
    } else {
      rule = 0;
    }
    return rule;
  });

export const orderBy = (array, key, order) =>
  array.sort((a, b) => {
    const aItem = typeof key === 'function' ? key(a) : indexObject(a, key);
    const bItem = typeof key === 'function' ? key(b) : indexObject(b, key);
    let direction = 1;

    if (order === 'desc') {
      direction = -1;
    }

    let rule;
    if (aItem > bItem) {
      rule = direction;
    } else if (bItem > aItem) {
      rule = -direction;
    } else {
      rule = 0;
    }
    return rule;
  });

export const groupBy = (array, condition) =>
  array.reduce((r, v) => {
    const group = typeof condition === 'function' ? condition(v) : v[condition];
    (r[group] || (r[group] = [])).push(v);
    return r;
  }, {});

export const asArray = value =>
  value ? (Array.isArray(value) ? value : [value]) : [];
