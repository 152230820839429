import { useEffect } from 'react';

const useScript = (url, shouldLoad = true, callback = () => {}) => {
  useEffect(() => {
    if (!shouldLoad) return;

    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = callback;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [shouldLoad]);
};

export default useScript;
