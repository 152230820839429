import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { rem } from '@feast-it/pesto';

import FormElement from '../FormElement';

import * as Styled from './styles';

const Input = forwardRef(
  (
    {
      type,
      placeholder,
      color,
      bg,
      fontSize,
      variant,
      height,
      persistentColor,
      field,
      form,
      icon,
      innerRef,
      ariaLabel,
      readOnly,
      ...props
    },
    ref
  ) => {
    const name = field.name;
    const error = form.touched[name] && form.errors[name];
    const isValid = !!form.touched[name] && !!field.value && !form.errors[name];
    const isInvalid = !!error;

    const formElementProps = {
      variant,
      error,
      isValid,
      isInvalid,
      ...props,
    };

    const inputProps = {
      type,
      placeholder,
      color,
      bg,
      fontSize,
      variant,
      height,
      error,
      persistentColor,
      ref: innerRef || ref,
      'aria-label': ariaLabel,
      readOnly,
      ...field,
    };

    return (
      <FormElement {...formElementProps}>
        <Styled.Input {...inputProps} />

        {icon && (
          <Styled.InputIcon name={icon} width={18} color="greyLightDark" />
        )}
      </FormElement>
    );
  }
);

export const variants = ['bordered', 'underlined', 'dotted', 'rounded'];

Input.displayName = 'Input';

Input.defaultProps = {
  type: 'text',
  color: 'blueDark',
  variant: 'underlined',
  fontSize: 'body',
  height: rem(40),
  form: { touched: [] },
};

Input.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  bg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(variants).isRequired,
  persistentColor: PropTypes.bool,
  innerRef: PropTypes.object,
  icon: PropTypes.string,
  ariaLabel: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default Input;
