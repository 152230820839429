import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { withRouter } from 'next/router';

import { isExternal } from '../../modules/externalLink';

const Link = ({ router, href, as, children, className, ...props }) => {
  const isActive = router.asPath.includes(href) || router.asPath.includes(as);

  if (isExternal(href)) {
    return (
      <a href={href} as={as} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <NextLink href={href} as={as}>
        <a className={isActive ? `${className} active` : className} {...props}>
          {children}
        </a>
      </NextLink>
    );
  }
};

Link.defaultProps = {
  className: '',
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
};

export default withRouter(Link);
