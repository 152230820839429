import { trackEvent } from './analytics';
import { getEventTitle } from './events';
import { GET_QUOTES_CLICKED } from '../constants/analytics';
import { EVENT_TYPE_ICONS } from '../constants/event-types';
import paths from '../constants/paths';
import { orderBy } from './helpers';
import { openLiveChat } from './liveChat';

const eventLinksMobile = [
  {
    groupLabel: 'What we offer',
    items: [
      {
        text: 'Corporate services',
        href: paths.EVENT('corporate-services'),
      },
      {
        text: 'Catering & street food',
        href: paths.EVENT('street-food-catering'),
      },
      {
        text: 'Food trucks',
        href: paths.EVENT('food-truck-catering'),
      },
      {
        text: 'Bar hire',
        href: paths.EVENT('mobile-bars'),
      },
      {
        text: 'Florists',
        href: 'https://feast-it.com/event/florists',
      },
      {
        text: 'Venues',
        href: paths.EVENT('venues-dry-hire-london'),
      },
      {
        text: 'Marquees & tents',
        href: paths.EVENT('marquees-tipis'),
      },
      {
        text: 'Photography',
        href: paths.EVENT('event-photography'),
      },
      {
        text: 'Videography',
        href: paths.EVENT('videographers'),
      },
      {
        text: 'Staff hire',
        href: paths.EVENT('event-staff'),
      },
      {
        text: 'Event planning',
        href: paths.EVENT('event-planning'),
      },
    ],
  },
  {
    groupLabel: 'Popular',
    items: [
      {
        text: 'Wedding catering',
        href: paths.EVENT('wedding-catering'),
      },
      {
        text: 'Party catering',
        href: paths.EVENT('party-catering'),
      },
      {
        text: 'Corporate catering',
        href: paths.EVENT('corporate-catering'),
      },
      {
        text: 'Event catering',
        href: paths.EVENT('event-catering'),
      },
      {
        text: 'School event catering',
        href: paths.EVENT('school-events'),
      },
      {
        text: 'Corporate photography',
        href: paths.EVENT('corporate-photography'),
      },
      {
        text: 'Event photography',
        href: paths.EVENT('event-photography'),
      },
      {
        text: 'Cocktail bar hire ',
        href: paths.EVENT('cocktail-bar-hire'),
      },
      {
        text: 'London dry venue hire',
        href: paths.EVENT('venues-dry-hire-london'),
      },
    ],
  },
];

const eventLinks = [
  {
    groupLabel: 'What we offer',
    items: [
      {
        text: 'Corporate services',
        href: paths.EVENT('corporate-services'),
      },
      {
        text: 'Catering & street food',
        href: paths.EVENT('street-food-catering'),
      },
      {
        text: 'Food trucks',
        href: paths.EVENT('food-truck-catering'),
      },
      {
        text: 'Bar hire',
        href: paths.EVENT('mobile-bars'),
      },
      {
        text: 'Venues',
        href: paths.EVENT('venues-dry-hire-london'),
      },
      {
        text: 'Marquees & tents',
        href: paths.EVENT('marquees-tipis'),
      },
      {
        text: 'Photography',
        href: paths.EVENT('event-photography'),
      },
      {
        text: 'Videography',
        href: paths.EVENT('videographers'),
      },
      {
        text: 'Staff hire',
        href: paths.EVENT('event-staff'),
      },
      {
        text: 'Event planning',
        href: paths.EVENT('event-planning'),
      },
    ],
  },
  {
    groupLabel: 'Popular',
    items: [
      {
        text: 'Wedding catering',
        href: paths.EVENT('wedding-catering'),
      },
      {
        text: 'School event catering',
        href: paths.EVENT('school-events'),
      },
      {
        text: 'Event agencies & planners',
        href: paths.EVENT('event-agencies'),
      },
      {
        text: 'Event catering',
        href: paths.EVENT('event-catering'),
      },
      {
        text: 'Event photography',
        href: paths.EVENT('event-photography'),
      },
      {
        text: 'Corporate catering',
        href: paths.EVENT('corporate-catering'),
      },
      {
        text: 'Corporate photography',
        href: paths.EVENT('corporate-photography'),
      },
      {
        text: 'University event catering',
        href: paths.EVENT('university-events'),
      },
      {
        text: 'Cocktail bar hire ',
        href: paths.EVENT('cocktail-bar-hire'),
      },
      {
        text: 'Mobile catering',
        href: paths.EVENT('mobile-catering'),
      },
      {
        text: 'Fish and chip van hire',
        href: paths.EVENT('mobile-fish-and-chip'),
      },
      {
        text: 'Ice cream van hire',
        href: paths.EVENT('ice-cream-van-hire'),
      },
      {
        text: 'Hog roast catering',
        href: paths.EVENT('hog-roast-catering'),
      },
      {
        text: 'BBQ catering',
        href: paths.EVENT('bbq-catering'),
      },
      {
        text: 'Pizza catering',
        href: paths.EVENT('pizza-catering'),
      },
      {
        text: 'Vegan catering',
        href: paths.EVENT('vegan-catering'),
      },
      {
        text: 'Vegetarian catering',
        href: paths.EVENT('vegetarian-catering'),
      },
      {
        text: 'Film and tv set catering',
        href: paths.EVENT('set-catering'),
      },
      {
        text: 'London dry venue hire',
        href: paths.EVENT('venues-dry-hire-london'),
      },
    ],
  },
];

const createNavigation = ({
  supplierSlug,
  messageCount,
  isSupplier,
  isLoggedIn,
  handleLogout,
  quoteCount,
  customerEvent,
  customerEvents,
  handleEventChange,
  isPublicEventsVisible,
}) => {
  const items = {
    community: {
      text: 'Perks',
      href: paths.COMMUNITY,
      target: '_blank',
    },
    magazine: {
      text: 'Magazine',
      href: isSupplier ? paths.MAGAZINE_CATEGORY('community') : paths.MAGAZINE,
      target: '_blank',
    },
    becomePartner: {
      text: 'Join as a supplier',
      href: paths.INFO(paths.INFO_PAGES.BECOME_PARTNER),
    },
    becomeVenue: {
      text: 'Join as a venue',
      href: paths.INFO(paths.INFO_PAGES.BECOME_VENUE_PARTNER),
    },
    browseSuppliers: {
      text: 'Browse',
      href: paths.BROWSE,
    },
    supplierAccountSettings: {
      text: 'Account settings',
      href: paths.SUPPLIER_MANAGE_ACCOUNT_SECTION('account'),
    },
    getQuotes: {
      text: 'Get quotes',
      href: paths.ENQUIRY_FORM,
      onClick: () => trackEvent(GET_QUOTES_CLICKED, { type: 'Header' }),
    },
    virtualEvent: {
      text: 'Virtual events',
      label: 'new',
      href: paths.EVENT(),
      as: paths.EVENT(paths.EVENT_PAGES.VIRTUAL_EVENTS),
    },
    createEvent: {
      text: 'Create an event',
      href: paths.ENQUIRY_FORM,
    },
    login: {
      text: 'Login',
      href: paths.LOGIN,
    },
    howWeWork: {
      text: 'How we work',
      href: paths.INFO(),
      as: paths.INFO(paths.INFO_PAGES.HOW_WE_WORK),
    },
    pickingOurSuppliers: {
      text: 'How we pick suppliers',
      href: paths.INFO(),
      as: paths.INFO(paths.INFO_PAGES.PICKING_OUR_SUPPLIERS),
    },
    whyFeastIt: {
      text: 'Why Feast It?',
      href: paths.INFO(),
      as: paths.INFO(paths.INFO_PAGES.WHY_FEAST_IT),
    },
    about: {
      text: 'About us',
      href: paths.INFO(),
      as: paths.INFO(paths.INFO_PAGES.ABOUT),
    },
    safetySecurity: {
      text: 'Safety & security',
      href: paths.INFO(),
      as: paths.INFO(paths.INFO_PAGES.SAFETY_SECURITY),
    },
    dashboard: {
      text: 'Dashboard',
      href: isSupplier ? paths.SUPPLIER_DASHBOARD : paths.DASHBOARD,
    },
    myDashboard: {
      text: 'My dashboard',
      href: isSupplier ? paths.SUPPLIER_DASHBOARD : paths.DASHBOARD,
    },
    favourites: {
      text: 'My favourites',
      href: paths.FAVOURITES,
    },
    payments: {
      text: 'My payments',
      href: paths.PAYMENTS,
    },
    messages: {
      text: 'Messages',
      href: paths.MESSAGES,
      label: messageCount ? messageCount.toString() : null,
    },
    settings: {
      text: 'Account settings',
      href: paths.SETTINGS,
    },
    feedback: {
      text: 'Give feedback',
      href: paths.FEEDBACK,
      target: '_blank',
    },
    reviews: {
      text: 'Add reviews',
      href: paths.REVIEWS,
    },
    referEvent: {
      text: 'Refer an event',
      href: paths.REFER_AN_EVENT,
    },
    calendar: {
      text: 'Calendar',
      href: paths.CALENDAR,
    },
    myTemplates: {
      text: 'My templates',
      href: paths.SUPPLIER_MY_TEMPLATES,
    },
    manage: {
      text: 'Manage your account',
      href: paths.SUPPLIER_MANAGE_ACCOUNT,
    },
    profile: {
      text: 'View profile',
      href: paths.SUPPLIER_PROFILE(supplierSlug),
    },
    logout: {
      text: 'Sign out',
      onClick: handleLogout,
    },
    customerEventsSelect: {
      text: 'My events',
      options: orderBy(customerEvents, 'date').map(event => ({
        label: getEventTitle(event),
        value: event.id,
        icon: EVENT_TYPE_ICONS[event.type] || 'Celebration',
      })),
      value: customerEvent,
      onChange: eventId => handleEventChange(eventId),
    },
    customerEventsList: {
      items: orderBy(customerEvents, 'date').map(event => ({
        text: getEventTitle(event),
        href: paths.CUSTOMER_EVENT(event.id),
      })),
    },
    liveChat: {
      text: 'Live chat',
      icon: 'Message',
      onClick: openLiveChat,
      color: 'primary',
    },
    help: {
      text: 'Help & resources',
      href: paths.FAQS,
      target: '_blank',
    },
    openEvents: {
      text: 'Open events',
      href: paths.SUPPLIER_EVENTS,
    },
    myQuotes: {
      text: 'My quotes',
      href: paths.SUPPLIER_QUOTES,
    },
    myBookings: {
      text: 'My bookings',
      href: paths.SUPPLIER_BOOKINGS,
    },
    publicEvents: {
      text: 'Feast It LIVE',
      href: paths.PUBLIC_EVENTS,
    },
    updates: {
      text: 'Updates',
      href: paths.UPDATES,
      target: '_blank',
    },
  };

  // Supplier navigation
  if (isSupplier && isLoggedIn) {
    return {
      navigation: [
        { type: 'link', ...items.dashboard },
        {
          type: 'secondary-link',
          text: 'Messages',
          isSidebarOnly: true,
          href: paths.MESSAGES,
          label: !!messageCount && {
            text: messageCount,
            bg: 'green',
            color: 'white',
          },
        },
        {
          type: 'list',
          text: 'Events',
          items: isPublicEventsVisible
            ? [
                items.openEvents,
                { ...items.myQuotes, countIndicator: quoteCount },
                items.myBookings,
                items.publicEvents,
              ]
            : [
                items.openEvents,
                { ...items.myQuotes, countIndicator: quoteCount },
                items.myBookings,
              ],
        },
        {
          type: 'list',
          text: 'Tools',
          toggleList: true,
          items: [
            items.calendar,
            {
              text: 'Payments',
              href: paths.SUPPLIER_PAYMENTS,
            },
            items.myTemplates,
            items.referEvent,
          ],
        },
        {
          type: 'list',
          text: 'Profile',
          toggleList: true,
          items: [
            {
              text: 'Edit profile',
              href: paths.SUPPLIER_MANAGE_ACCOUNT,
            },
            items.reviews,
          ],
        },
        {
          type: 'list',
          text: 'Community',
          toggleList: true,
          items: [
            items.community,
            items.magazine,
            { ...items.updates, isSidebarOnly: true },
          ],
        },
        {
          type: 'list',
          isSidebarOnly: true,
          items: [
            items.liveChat,
            items.help,
            items.supplierAccountSettings,
            items.feedback,
            items.logout,
          ],
        },
      ],
      userItems: [
        items.supplierAccountSettings,
        items.help,
        items.feedback,
        items.logout,
      ],
      actions: {
        desktop: [{ type: 'headway' }, { ...items.messages, icon: 'Message' }],
        mobile: { icon: 'Message', ...items.messages },
        tablet: [
          { type: 'button', ...items.dashboard },
          {
            type: 'iconButton',
            text: 'Notification',
            icon: 'Notification',
            href: paths.UPDATES,
            target: '_blank',
          },
          {
            type: 'iconButton',
            icon: 'Message',
            ...items.messages,
            label: messageCount ? messageCount : '',
          },
        ],
      },
    };
  }

  if (isLoggedIn) {
    // Customer navigation
    const desktop = [
      {
        type: 'primaryButton',
        ...items.createEvent,
      },
      {
        type: 'iconButton',
        icon: 'Message',
        ...items.messages,
      },
      {
        type: 'user',
        items: [
          items.myDashboard,
          items.favourites,
          items.settings,
          items.logout,
        ],
      },
    ];

    const mobile = [
      {
        type: 'link',
        ...items.dashboard,
      },
      {
        type: 'list',
        items: [...items.customerEventsList.items, items.createEvent],
      },
      {
        type: 'list',
        items: [items.favourites, items.payments, items.settings, items.logout],
      },
      {
        type: 'list',
        items: [items.magazine, items.browseSuppliers],
      },
    ];

    // We only want the event select if there are some events to select
    if (customerEvents.length) {
      desktop.unshift({
        type: 'select',
        ...items.customerEventsSelect,
      });
    }

    return { desktop, mobile };
  }

  // Logged out navigation
  return {
    desktop: [
      {
        type: 'list',
        text: 'How we work',
        items: [
          items.howWeWork,
          items.pickingOurSuppliers,
          items.whyFeastIt,
          items.about,
          items.safetySecurity,
        ],
      },
      {
        type: 'groupedList',
        text: 'What we offer',
        rowLength: 10,
        items: eventLinks,
      },
      {
        type: 'link',
        ...items.magazine,
      },
      {
        type: 'list',
        text: 'Join Feast It',
        items: [items.becomePartner, items.becomeVenue],
      },
      {
        type: 'link',
        ...items.browseSuppliers,
      },
      {
        type: 'primaryButton',
        ...items.getQuotes,
      },
      {
        type: 'secondaryButton',
        ...items.login,
      },
    ],
    mobile: [
      {
        type: 'primaryButton',
        ...items.getQuotes,
      },
      {
        type: 'secondaryButton',
        ...items.login,
      },
      {
        type: 'groupedList',
        text: 'Events',
        rowLength: 10,
        items: eventLinksMobile,
      },
      {
        type: 'list',
        items: [
          items.magazine,
          items.becomePartner,
          items.becomeVenue,
          items.browseSuppliers,
          items.getQuotes,
        ],
      },
      {
        type: 'list',
        items: [
          items.howWeWork,
          items.pickingOurSuppliers,
          items.whyFeastIt,
          items.about,
          items.safetySecurity,
        ],
      },
    ],
  };
};

export default createNavigation;
