export const TIER_1_ICONS = {
  food: 'KnifeFork',
  drink: 'Drink',
  audio_visual: 'Camera',
  staff: 'Group',
  venues: 'Venue',
  florists: 'Flower',
  marquees_tents: 'Marquees',
  virtual: 'VirtualThick',
};

export const VERTICAL_ICONS_TOGATHER = {
  food: 'Food',
  drink: 'Drinks',
  audio_visual: 'Photography',
  staff: 'Staffing',
  venues: 'Venues',
  florists: 'Florist',
  marquees_tents: 'Marquees',
  virtual: 'Virtual',
};

export const TIER_1_ICONS_THIN = {
  food: 'Food',
  drink: 'Drinks',
  audio_visual: 'Photography',
  staff: 'Staffing',
  venues: 'Venues',
  florists: 'Florist',
  marquees_tents: 'Marquees',
  virtual: 'Virtual',
};

export const TIER_2_IMAGES = {
  street_food: '/images/verticals/food-streetfood.jpg',
  caterers: '/images/verticals/food-caterers.jpg',
  restaurant: '/images/verticals/food-caterers.jpg',
  marquees_tipis: '/images/verticals/marqueestents-marquee.jpg',
  glamping: '/images/verticals/marqueestents-glamping.jpg',
  florists: '/images/verticals/florists-florists.jpg',
  city: '/images/verticals/venue-city.jpg',
  countryside: '/images/verticals/venue-countryside.jpg',
  private_chef: '/images/verticals/food-privatechef.jpg',
  mobile_bar: '/images/verticals/drinks-mobilebar.jpg',
  event_bar: '/images/verticals/drinks-eventbar.jpg',
  photography: '/images/verticals/photovideo-photography.jpg',
  videography: '/images/verticals/photovideo-videography.jpg',
  hospitality_staff: '/images/verticals/staff-hospitality.jpg',
  event_staff: '/images/verticals/staff-event.jpg',
  virtual: '/images/verticals/virtual-event.jpg',
  venues: '/images/verticals/venue-city.jpg',
};

export const BROWSE_FILTERS = {
  food: [
    {
      name: 'Cuisine',
      tagsType: 'food',
    },
    {
      name: 'Dietary',
      tagsType: 'dietary',
    },
  ],
  drink: [
    {
      name: 'Drink types',
      tagsType: 'drink',
    },
  ],
  audio_visual: [
    {
      name: 'Style',
      tagsType: 'photo',
    },
  ],
  staff: [
    {
      name: 'Staff types',
      tagsType: 'staff',
    },
  ],
  venues: [
    {
      name: 'Venue types',
      tagsType: 'venues',
    },
  ],
  florists: [
    {
      name: 'Floristry types',
      tagsType: 'florist',
    },
  ],
  marquees_tents: [
    {
      name: 'Marquee/tent types',
      tagsType: 'marquees',
    },
  ],
  virtual: [],
};

export const TIER_2_TYPES = {
  street_food: {
    sys_name: 'street_food',
    label: 'Street food',
    parent: 'food',
  },
  caterers: {
    sys_name: 'caterers',
    parent: 'food',
    label: 'Caterers',
  },
  private_chef: {
    sys_name: 'private_chef',
    label: 'Private Chef',
    parent: 'food',
  },
  marquees_tipis: {
    sys_name: 'marquees_tipis',
    parent: 'marquees_tents',
    label: 'Marquees & Tipis',
  },
  glamping: {
    sys_name: 'glamping',
    parent: 'marquees_tents',
    label: 'Glamping',
  },
  florists: {
    sys_name: 'florists',
    parent: 'florists',
    label: 'Florists',
  },
  mobile_bar: {
    sys_name: 'mobile_bar',
    parent: 'drink',
    label: 'Mobile Bars',
  },
  event_bar: {
    sys_name: 'event_bar',
    parent: 'drink',
    label: 'Event Bars',
  },
  photography: {
    sys_name: 'photography',
    parent: 'audio_visual',
    label: 'Photographers',
  },
  videography: {
    sys_name: 'videography',
    parent: 'audio_visual',
    label: 'Videographers',
  },
  hospitality_staff: {
    sys_name: 'hospitality_staff',
    parent: 'staff',
    label: 'Hospitality Staff',
  },
  event_staff: {
    sys_name: 'event_staff',
    parent: 'staff',
    label: 'Event Staff',
  },
  virtual: {
    sys_name: 'virtual',
    parent: 'virtual',
    label: 'Virtual Experiences',
  },
  venues: {
    sys_name: 'venues',
    parent: 'venues',
    label: 'Venues',
  },
};
