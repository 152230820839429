import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@feast-it/pesto';

import * as Styled from './styles';

export default function FormElement({
  as,
  label,
  error,
  validColor,
  invalidColor,
  isValid,
  isInvalid,
  inlineValidation,
  variant,
  children,
  ...props
}) {
  return (
    <Styled.FormElement {...props} as={as || (label && 'label')}>
      {label && (
        <Text
          variant="small"
          color="greyDark"
          textAlign="left"
          mb={variant === 'underlined' ? -2 : 1}
        >
          {label}
        </Text>
      )}

      <Styled.InputWrapper>
        {children}

        {inlineValidation && (isValid || isInvalid) && (
          <Styled.ValidationIcon
            name={isValid ? 'Valid' : 'Invalid'}
            color={isValid ? validColor : invalidColor}
            borderedInput={variant === 'bordered'}
            width={15}
            height={15}
          />
        )}
      </Styled.InputWrapper>

      {!inlineValidation && error && (
        <Text variant="extraSmall" color="red" textAlign="left" mt={1}>
          {error}
        </Text>
      )}
    </Styled.FormElement>
  );
}

export const variants = ['bordered', 'underlined', 'dotted', 'rounded'];

FormElement.defaultProps = {
  validColor: '#14d37d',
  invalidColor: '#ff5a5f',
  variant: 'underlined',
};

FormElement.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  validColor: PropTypes.string,
  invalidColor: PropTypes.string,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  inlineValidation: PropTypes.bool,
  variant: PropTypes.oneOf(variants).isRequired,
};
