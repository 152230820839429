import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Header, SupplierNavigationBar } from '@feast-it/pesto';
import { isFuture } from 'date-fns';

import createNavigation from '../../modules/createNavigation';
import { logout } from '../../modules/user';
import { watchMessages } from '../../modules/messages';
import paths from '../../constants/paths';

import Link from '../../components/Link';
import SUPPLIER_TOTAL_QUOTES from '../../queries/supplier-quotes.gql';
import CustomerEventsContext from '../../context/customerEvents';
import UserContext from '../../context/user';
import { captureError } from '../../modules/monitoring';
import useHeadway from '../../components/Headway/useHeadway';

const HeaderContainer = ({
  headerType,
  headerCTA,
  transparentHeader,
  borderlessHeader,
}) => {
  useHeadway('#headway', '#headway-trigger');
  const [messageCount, setMessageCount] = useState(0);
  const { eventId, events } = useContext(CustomerEventsContext);
  const { isLoggedIn, user } = useContext(UserContext);

  const handleLogout = useCallback(() => logout(), []);
  const isSupplier = user?.type === 'supplier';
  const supplierSlug = user?.suppliers?.[0]?.slug;
  const isPublicEventsVisible = user?.suppliers?.[0]?.public_events_visible;

  const { data: supplierQuotes } = useQuery(SUPPLIER_TOTAL_QUOTES, {
    variables: {
      supplierId: user?.suppliers?.[0]?.uuid,
    },
    skip: !user?.suppliers?.[0]?.uuid,
    onError: error => {
      captureError(error);
    },
  });

  const quoteCount = supplierQuotes?.supplierTotalQuotes?.count;

  useEffect(() => {
    if (!isLoggedIn) return;

    // Initialise messaging socket
    watchMessages(setMessageCount);
  }, [isLoggedIn, setMessageCount]);

  const futureEvents = useMemo(
    () => events?.filter(event => isFuture(new Date(event.date))),
    [events]
  );

  const handleEventChange = useCallback(newEventId => {
    if (newEventId !== '') {
      window.location.href = paths.CUSTOMER_EVENT(newEventId);
    }
  }, []);

  let logoHref = paths.HOME;

  if (isLoggedIn) {
    logoHref = isSupplier ? paths.SUPPLIER_DASHBOARD : paths.DASHBOARD;
  }

  const navigation = useMemo(
    () =>
      createNavigation({
        supplierSlug,
        messageCount,
        isLoggedIn,
        isSupplier,
        quoteCount,
        handleLogout,
        customerEvent: eventId,
        customerEvents: futureEvents,
        handleEventChange,
        isPublicEventsVisible,
      }),
    [
      supplierSlug,
      messageCount,
      isLoggedIn,
      isSupplier,
      handleLogout,
      quoteCount,
      eventId,
      futureEvents,
      handleEventChange,
      isPublicEventsVisible,
    ]
  );

  // Only show the primary button if lean header type is selected
  if (headerType === 'lean' && !isLoggedIn) {
    navigation.desktop = navigation.desktop.filter(
      ({ type }) => type === 'primaryButton'
    );
  }

  // Overwrite the primary button link if optional CTA is provided
  if (headerCTA) {
    navigation.desktop.find(
      ({ type }) => type === 'primaryButton'
    ).href = headerCTA;
  }

  return (
    <>
      {isLoggedIn && isSupplier ? (
        <SupplierNavigationBar
          logoHref={logoHref}
          navigation={navigation.navigation}
          user={{
            type: 'user',
            firstName: user.firstName,
            items: navigation.userItems,
          }}
          actions={navigation.actions}
        />
      ) : (
        <Header
          logoHref={logoHref}
          navigation={navigation}
          user={user}
          transparent={transparentHeader}
          borderless={borderlessHeader}
          hasMessages={!!messageCount}
          mobileAction={
            isLoggedIn
              ? {
                  icon: 'Message',
                  text: 'Messages',
                  label: messageCount || null,
                  href: paths.MESSAGES,
                }
              : null
          }
          linkComponent={Link}
        />
      )}
    </>
  );
};

HeaderContainer.propTypes = {
  headerCTA: PropTypes.string,
  headerType: PropTypes.oneOf(['default', 'lean', null]),
  transparentHeader: PropTypes.bool,
  borderlessHeader: PropTypes.bool,
};

export default HeaderContainer;
