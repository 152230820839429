import cookies from 'js-cookie';
import io from 'socket.io-client';
import Router from 'next/router';

import { api as baseURL } from '../config';
import {
  READ_ONE,
  UNREAD_COUNT_RECEIVED,
  UNREAD_COUNT_REQUESTED,
  MESSAGE_MARKED_READ,
} from '../constants/sockets';
import { api } from './api';
import paths from '../constants/paths';

let socketInstance;

const createSocket = () => {
  const token = cookies.get('AUTHENTICATION_TOKEN_V2');

  if (!token) return;

  return io(baseURL, {
    query: { token },
  });
};

const getSocket = () => {
  if (socketInstance) {
    return socketInstance;
  } else {
    return createSocket();
  }
};

export const watchMessages = cb => {
  const socket = getSocket();

  socket.on('connect', () => {
    socket.emit('subscribe');
    socket.emit(UNREAD_COUNT_REQUESTED);
  });

  socket.on('reconnect', () => {
    socket.emit('subscribe');
  });

  socket.on(READ_ONE, () => {
    socket.emit(UNREAD_COUNT_REQUESTED);
  });

  socket.on(MESSAGE_MARKED_READ, () => {
    socket.emit(UNREAD_COUNT_REQUESTED);
  });

  socket.on(UNREAD_COUNT_RECEIVED, data => {
    cb(data);
  });
};

export const getQuoteConversation = async quoteId => {
  const conversation = await api.get(`/bookings-v2/${quoteId}/getConversation`);

  if (conversation?.data?.uuid) {
    Router.push(`${paths.CONVERSATION(conversation.data.uuid)}`);
  }
};
