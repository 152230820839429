import React from 'react';
import Link from 'next/link';
import {
  Container,
  Heading,
  Text,
  Icon,
  rem,
  GridItem,
  Box,
} from '@feast-it/pesto';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import NewsletterSignup from './NewsletterSignup';
import paths from '../../constants/paths';
import { isExternal } from '../../modules/externalLink';
import useScript from '../../hooks/useScript';

import * as Styled from './styles';

const year = new Date().getFullYear();

const Footer = ({ navigation, paymentTypes, socialLinks, services }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useScript(
    'https://widget.reviews.co.uk/badge/dist.js',
    inView,
    () =>
      window.reviewsBadge &&
      window.reviewsBadge('badge-140', {
        store: 'feast-it-com',
        primaryClr: '#12d06c',
        neutralClr: '#f4f4f4',
        starsClr: '#fff',
        textClr: '#fff',
      })
  );

  return (
    <Styled.Footer ref={ref}>
      <Container>
        <Styled.Inner>
          <Styled.Area name="servicesLinks">
            <Styled.ServiceContainer gridRowGap={9}>
              {Object.entries(services).map(([sectionKey, service]) => {
                return (
                  <GridItem key={`item-${sectionKey}`}>
                    <Box mb={2}>
                      <Styled.Header
                        variant="headingSmall"
                        color="greyDark"
                        fontSize="body"
                        as="a"
                        href={service.href}
                      >
                        {service.heading}
                      </Styled.Header>
                    </Box>

                    <ul>
                      {service.items.map(item => (
                        <li key={`footer-item-${item.text}`}>
                          <Styled.Link href={item.href} onClick={item.onClick}>
                            {item.text}
                          </Styled.Link>
                        </li>
                      ))}
                    </ul>
                  </GridItem>
                );
              })}
            </Styled.ServiceContainer>
          </Styled.Area>
          <Styled.Area name="details">
            <Styled.DetailContainer
              gridRowGap={9}
              gridTemplateColumns={{
                m: '1fr 1fr',
              }}
            >
              <Styled.ReviewsContainer name="reviews">
                <Styled.GoogleReviews
                  src="/images/generic/google-review.png"
                  alt="Google Reviews, 5 stars"
                  width={140}
                  height={75}
                  layoutProps={{
                    width: { _: rem(140), l: rem(140) },
                  }}
                  inView={inView}
                  name="goolgeStars"
                />

                <Styled.Reviews id="badge-140" name="reviewBadge" />
              </Styled.ReviewsContainer>

              {Object.entries(navigation).map(([sectionKey, section]) => {
                return (
                  <GridItem key={`item-${sectionKey}`}>
                    <Heading
                      variant="headingSmall"
                      color="greyDark"
                      fontSize="body"
                      mb={2}
                    >
                      {section.heading}
                    </Heading>

                    <ul>
                      {section.items.map(item => (
                        <li key={`footer-item-${item.text}`}>
                          {isExternal(item.href) ? (
                            <Styled.Link
                              href={item.href}
                              onClick={item.onClick}
                            >
                              {item.text}
                            </Styled.Link>
                          ) : (
                            <Link href={item.href} as={item.as} passHref>
                              <Styled.Link onClick={item.onClick}>
                                {item.text}
                              </Styled.Link>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </GridItem>
                );
              })}
            </Styled.DetailContainer>
          </Styled.Area>

          <Styled.Area name="payments">
            <Heading
              as="h2"
              variant="headingSmall"
              color="blueDark"
              mb={2}
              textAlign={{ _: 'center', m: 'center', l: 'left' }}
            >
              Payments
            </Heading>

            <Styled.PaymentGrid>
              {paymentTypes.map(type => (
                <Icon
                  key={`payment-type-${type}`}
                  aria-label={type}
                  width={{ _: 50, l: 76 }}
                  height={{ _: 50, l: 50 }}
                  name={type}
                  color="greyDark"
                />
              ))}
            </Styled.PaymentGrid>
          </Styled.Area>

          <Styled.Area name="copyright">
            <Styled.Logo>
              <Link href={paths.HOME}>
                <a aria-label="Go to homepage">
                  <Icon name="Logo" width={158} height={50} color="blueDark" />
                </a>
              </Link>
            </Styled.Logo>

            <Text variant="extraSmall" color="greyDark" mt={1}>
              &copy; {year} Karamu Ltd trading as Feast It.
              All&nbsp;rights&nbsp;reserved.
            </Text>
          </Styled.Area>

          <Styled.Area name="newsletter">
            <NewsletterSignup />

            <Styled.SocialLinks>
              {socialLinks.map(({ type, href }) => (
                <Styled.SocialLink href={href} key={`${type}`}>
                  <Icon
                    name={type}
                    aria-label={type}
                    width={{ _: 36, l: 20 }}
                    height={{ _: 36, l: 20 }}
                  />
                </Styled.SocialLink>
              ))}
            </Styled.SocialLinks>
          </Styled.Area>
        </Styled.Inner>
      </Container>
    </Styled.Footer>
  );
};

Footer.propTypes = {
  navigation: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  socialLinks: PropTypes.array.isRequired,
  services: PropTypes.object.isRequired,
};

export default Footer;
