import { TIER_2_TYPES } from './verticals';

export const EVENT_TYPE_ICONS = {
  Wedding: 'Wedding',
  'Corporate Event': 'Corporate',
  Birthday: 'Birthday',
  'Dinner Party': 'DinnerParty',
  Celebration: 'Celebration',
  'Public-facing Event': 'PublicEvents',
};

export const EVENT_TYPES = [
  {
    label: 'Wedding',
    value: 'Wedding',
    icon: EVENT_TYPE_ICONS.Wedding,
  },
  {
    label: 'Corporate event',
    value: 'Corporate Event',
    icon: EVENT_TYPE_ICONS['Corporate Event'],
  },
  {
    label: 'Birthday',
    value: 'Birthday',
    icon: EVENT_TYPE_ICONS.Birthday,
  },
  {
    label: 'Dinner party',
    value: 'Dinner Party',
    icon: EVENT_TYPE_ICONS['Dinner Party'],
  },
  {
    label: 'Celebration',
    value: 'Celebration',
    icon: EVENT_TYPE_ICONS.Celebration,
  },
  {
    label: 'Public-facing event',
    value: 'Public-facing Event',
    icon: EVENT_TYPE_ICONS['Public-facing Event'],
  },
];

export const EVENT_ADDITIONAL_SERVICES = {
  Wedding: [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
  'Corporate Event': [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
  Birthday: [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
  'Dinner Party': [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
  Celebration: [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
  'Public-facing Event': [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
  default: [
    TIER_2_TYPES.street_food,
    TIER_2_TYPES.caterers,
    TIER_2_TYPES.mobile_bar,
    TIER_2_TYPES.photography,
    TIER_2_TYPES.videography,
    TIER_2_TYPES.florists,
    TIER_2_TYPES.marquees_tipis,
    TIER_2_TYPES.glamping,
    TIER_2_TYPES.private_chef,
    TIER_2_TYPES.event_bar,
    TIER_2_TYPES.event_staff,
    TIER_2_TYPES.hospitality_staff,
    TIER_2_TYPES.virtual,
    TIER_2_TYPES.venues,
  ],
};
