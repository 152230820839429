import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { ThemeProvider } from 'emotion-theming';
import { base, wedding, carbon, BannerHeader, Box } from '@feast-it/pesto';

import Header from '../header';
import Footer from '../footer';
import UserContext from '../../context/user';

const PrismicPreview = dynamic(() => import('../../components/PrismicPreview'));

const themes = {
  base: {
    ...base,
    colors: {
      ...base.colors,
      primary: '#345FF2',
      secondary: '#00387F',
      tertiary: '#FF7364',
      backDrop: '#EEF3F6',
      primaryDark: '#0F2145',
    },
  },
  wedding,
  carbon,
  christmas: {
    ...base,
    colors: {
      ...base.colors,
      primary: '#097B5D',
      secondary: '#AB001F',
      tertiary: '#FFD390',
      backDrop: '#FAF3F1',
      primaryDark: '#09614C',
      greyLight: '#FAF3F1',
    },
  },
};

const Layout = ({
  theme,
  headerType,
  headerCTA,
  borderlessHeader,
  transparentHeader,
  children,
  showHeader,
  showFooter,
  prismicPreview,
}) => {
  const { user } = useContext(UserContext);

  return (
    <ThemeProvider theme={themes[theme]}>
      <Box position="fixed" overflow="auto" height="100%" width="100%">
        {user?.suppliers?.[0]?.state === 'submitted' && (
          <BannerHeader
            width="100%"
            px={{
              _: 'gutter._',
              s: 'gutter.s',
            }}
            textAlign="center"
            py={2}
            icon="TickCircle"
            message="Application submitted! Your profile is under review."
          />
        )}
        {showHeader && (
          <Header
            headerType={headerType}
            headerCTA={headerCTA}
            borderlessHeader={borderlessHeader}
            transparentHeader={transparentHeader}
          />
        )}

        {children}

        {showFooter && <Footer />}

        {prismicPreview && <PrismicPreview />}
      </Box>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  theme: 'base',
  showFooter: true,
  showHeader: true,
};

Layout.propTypes = {
  theme: PropTypes.oneOf(['base', 'wedding', 'carbon']),
  headerCTA: PropTypes.string,
  headerType: PropTypes.oneOf(['default', 'lean', null]),
  borderlessHeader: PropTypes.bool,
  transparentHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  prismicPreview: PropTypes.bool,
};

export default Layout;
