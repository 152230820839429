import styled from '@emotion/styled/macro';
import { color, typography, layout } from 'styled-system';
import { Icon, inputReset } from '@feast-it/pesto';

const variants = (theme, variant) => {
  switch (variant) {
    case 'bordered':
      return `
        padding: 0 ${theme.space[3]};
        border: 1px solid ${theme.colors.greyMedium};
        border-radius: ${theme.radii[0]};
      `;
    case 'underlined':
      return `
        border-bottom: 1px solid ${theme.colors.greyMedium};
      `;
    case 'dotted':
      return `
        border-bottom: 2px dotted ${theme.colors.greyMedium};
      `;
    case 'rounded':
      return `
        padding-left: ${theme.space[4]};
        background: ${theme.colors.greyLight};
        border-radius: ${theme.radii[4]};
      `;
    default:
      return null;
  }
};

export const Input = styled.input`
  ${inputReset}
  width: 100%;
  font-variant-ligatures: no-contextual;
  ${({ theme, variant }) => variants(theme, variant)}
  ${({ theme, error }) => !!error && `border-color: ${theme.colors.red};`}
  ${typography}
  ${layout}
  ${color}

  &:focus {
    border-color: currentColor;
  }

  ${({ persistentColor }) =>
    persistentColor &&
    `
    border-color: currentColor;

    &::placeholder {
      color: currentColor;
      opacity: 1;
    }
  `}
`;

export const InputIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
