import styled from '@emotion/styled/macro';
import { space, layout, color } from 'styled-system';
import { Icon } from '@feast-it/pesto';

export const FormElement = styled.div`
  display: block;
  width: 100%;
  ${space}
  ${layout}
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ValidationIcon = styled(Icon)`
  ${color}
  position: absolute;
  top: 50%;
  right: ${({ borderedInput, theme }) => (borderedInput ? theme.space[4] : 0)};
  display: inline-flex;
  transform: translateY(-50%);
  pointer-events: none;
`;
