import { useEffect } from 'react';

import { headwayId } from '../../config';

const useHeadway = (selector, trigger) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const config = {
      account: headwayId,
      selector,
      trigger,
    };
    script.onload = () => {
      window.Headway.init(config);
    };
  }, []);
};

export default useHeadway;
